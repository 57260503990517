import { Config, ConnectorNames } from "./types";

const connectors: Config[] = [
  {
    title: "Metamask",
    icon: "../images/wallet-metamask.svg",
    connectorId: ConnectorNames.Injected,
  }
];

export default connectors;
export const connectorLocalStorageKey = "connectorId";
