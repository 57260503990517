const tokenInvData = [{
    "id": "1",
    "tokenAddress": "0x51b5619f5180e333d18b6310c8d540aea43a0371",
    "invDashAddress": "0xd0fe06Dd3B378851827f2aF3E7B9e3d78be95F27",
    "name": "VaultHill",
    "url_name": "vaulthill",
    "symbol": "VHC",
    "chainId": "137",
    "decimals": "18",
    "totalSupply": "274000000",
    "createdAt": "2022-01-26T00:00:00.000Z",
    "updatedAt": "2022-01-26T00:00:00.000Z",
    "totalTokenLocked": "2000000",
    "usdPrice": 0.05,
    "imgUrl": "https://i.imgur.com/9WJ5miO.png"
}, {
    "id": "2",
    "tokenAddress": "0x3642c3c8eBe0b36a29e37809e1c9A7DE06BC0B26",
    "invDashAddress": "0x2C22c38280079E36f1B7E7bE9Fa4d93aF0737475",
    "name": "MaticLaunch",
    "url_name": "maticLaunch",
    "symbol": "MTCL",
    "chainId": "137",
    "decimals": "18",
    "totalSupply": "20000000",
    "createdAt": "2022-01-31T00:00:00.000Z",
    "updatedAt": "2022-01-31T00:00:00.000Z",
    "totalTokenLocked": "935385",
    "usdPrice": 0.1,
    "imgUrl": "https://assets.coingecko.com/coins/images/18036/large/TQzTNi5.png?1630312183"
}, {
    "id": "3",
    "tokenAddress": "0x37ac5f3bfd18a164fc6cf0f0f0ecd334d9179d57",
    "invDashAddress": "0xd0fe06Dd3B378851827f2aF3E7B9e3d78be95F27",
    "name": "Vulture Peak",
    "url_name": "vulturepeak",
    "symbol": "VPK",
    "chainId": "56",
    "decimals": "18",
    "totalSupply": "30000000",
    "createdAt": "2022-02-15T00:00:00.000Z",
    "updatedAt": "2022-02-15T00:00:00.000Z",
    "totalTokenLocked": "106787.7",
    "usdPrice": 0.35,
    "imgUrl": "https://i.imgur.com/TeAOkCz.png"
}, {
    "id": "4",
    "tokenAddress": "0x7d55d94da49e030964330ef6af62aa5e4aa1c458",
    "invDashAddress": "0x2C22c38280079E36f1B7E7bE9Fa4d93aF0737475",
    "name": "AssetFi",
    "url_name": "assetfi",
    "symbol": "AFI",
    "chainId": "56",
    "decimals": "18",
    "totalSupply": "500000000",
    "createdAt": "2022-03-14T00:00:00.000Z",
    "updatedAt": "2022-03-14T00:00:00.000Z",
    "totalTokenLocked": "1342240.5",
    "usdPrice": 0.015,
    "imgUrl": "https://pbs.twimg.com/profile_images/1416028552600956947/T4vDHGTH_400x400.jpg"
}, {
    "id": "5",
    "tokenAddress": "0x06597ffafd82e66eced9209d539032571abd50d9",
    "invDashAddress": "0xC354969e6a7F0BF65288519373759Ecb082732Eb",
    "name": "Mollector",
    "url_name": "mollector",
    "symbol": "MOL",
    "chainId": "56",
    "decimals": "18",
    "totalSupply": "1000000000",
    "createdAt": "2022-03-28T00:00:00.000Z",
    "updatedAt": "2022-03-28T00:00:00.000Z",
    "totalTokenLocked": "1389195.5",
    "usdPrice": 0.015,
    "imgUrl": "https://i.imgur.com/9Rx8VXy.png"
}]


export function getInvDashDetailsbyAddress(tokenAddress) {
    let pool = tokenInvData.filter((item) => item.tokenAddress.toLowerCase() === tokenAddress.toLowerCase())
    if (pool.length === 0) return undefined
    return pool[0]
}

export function getInvDashDetailsbyName(name) {
    if(name === undefined) {
        name="MaticLaunch"
    }
    let pool = tokenInvData.filter((item) => item.url_name.toLowerCase() === name.toLowerCase())
    if (pool.length === 0) return undefined
    return pool[0]
}
