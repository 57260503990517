import React, { Suspense, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import BigNumber from 'bignumber.js'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../components/Header'
import Footer from '../components/Footer'
import Web3ReactManager from '../components/Web3ReactManager';


import InvestorLockDetails from './lock-details-investor-vesting';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Collapse } from 'react-bootstrap';

// import 'react- react-toastify/dist/ReactToastify.css';

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

toast.configure(
  {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  }
)

function App() {
  const [notiHeader, setNotiHeader] = useState(sessionStorage.getItem("notiHeader") === 'true' ? false : true)
  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Web3ReactManager>
          <>
            {/* <Collapse mountOnEnter={true} in={notiHeader} timeout={1000}>
              <div className="airdrop-header">
                <div className="airdrop-header-content">
                  <a href="https://gleam.io/xJCqA/blockpad-early-supporter-airdrop-event">BlockPad early supporter airdrop event is now live.</a></div>
                <div className="airdrop-header-close" onClick={() => {
                  setNotiHeader(false)
                  sessionStorage.setItem("notiHeader", "true")
                }}>X</div>
              </div>
            </Collapse> */}
            <Header></Header>
            <div className="body-content">
              <Routes>
                <Route path="/:tokenAddress" element={<InvestorLockDetails />} />
                <Route path="" element={<InvestorLockDetails />} />
              </Routes>
            </div>
            <Footer></Footer>
          </>
        </Web3ReactManager>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
