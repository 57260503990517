import React from 'react'
import { Modal } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'
import connectors from './config'
import { injected } from '../../connectors'
import './wallet-connect.css'
import { toast } from 'react-toastify'

export declare type ConnectorId = "authereum" | "fortmatic" | "frame" | "injected" | "portis" | "squarelink" | "torus" | "walletconnect" | "walletlink" | "bsc";

function ConnectWallet(props: any) {

    const { activate } = useWeb3React()

    const handleLogin = () => {

        props.onHide()
        return activate(injected, undefined, true).catch((e) => {
            // alert(e.message)
            toast.error(e.message)
        })
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="brand-color-white"
        >
            <Modal.Header closeButton className="brand-bgcolor-shadow-black">
                <Modal.Title className="ml-auto" id="contained-modal-title-vcenter">
                    Connect To wallet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="brand-bgcolor-shadow-black">
                {connectors.map((entry, index) => (
                    <div key={index}>
                        <div className="wallet-item" 
                        onClick={() => {
                            window.localStorage.setItem('connectorId', entry.connectorId);
                            handleLogin()
                        }}>
                            <div className="d-flex p-3 align-items-center">
                                <img alt={entry.icon} width="40px" src={entry.icon} />
                                <h5 className="ml-4 mb-0">{entry.title}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default ConnectWallet