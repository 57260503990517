import React, { useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import ConnectWallet from '../WalletConnect'
import AccountDetails from '../AccountDetails'
import { useWeb3React } from '@web3-react/core'
import Logo from "./images/logo-white.svg";
import './header.css'

const Header = () => {
    const [connectWalletModalShow, setConnectWalletModalShow] = useState(false);
    const [accountDetailsModalShow, setAccountDetailsModalShow] = useState(false);
    const { account, active } = useWeb3React();
    const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null;

    function onConnectWalletClick() {
        if (!active) {
            setConnectWalletModalShow(true)
            return
        }
        setAccountDetailsModalShow(true)
    }

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand href="https://maticlaunch.org/">
                <img alt="logo" className="brand-logo" src={Logo}></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                </Nav>
                <Nav>
                    <Nav className="justify-content-end align-items-center navbar-list">
                        <Nav.Item>
                            <button className="btn btn-brand-header text-nowrap mr-3 connect-wallet-btn" onClick={onConnectWalletClick}>
                                {!active ? 'CONNECT WALLET' : accountEllipsis}
                            </button>
                        </Nav.Item>
                    </Nav>
                </Nav>
            </Navbar.Collapse>
            <ConnectWallet
                show={connectWalletModalShow}
                onHide={() => setConnectWalletModalShow(false)}
            />
            <AccountDetails
                show={accountDetailsModalShow}
                onHide={() => setAccountDetailsModalShow(false)}
            />
        </Navbar>
    )
}

export default Header
